import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { DropdownOverlay } from '@ab-core/dropdownoverlay';
import {
    isGhostAccess,
    useMediaQuery,
    userCanSeeCredits,
    userCanSeeTecselect,
    userHasOnlySalesView
} from '@ab-core/functions';
import { isSalesViewEnabled, toggleSalesView } from '@ab-core/functions/salesView';
import { useAuth, userHasPermissions } from '@ab-core/hooks';
import { LoginBox } from '@ab-core/loginbox';
import { Search } from '@ab-core/search';
import { navigationStore } from '@ab-core/store';
import { getTestIdProp } from '@ab-core/testing';
import { toast } from '@ab-core/toast';
import { UserSettings } from '@ab-core/usersettings';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { PointsOverview } from '../components';
import { Brand } from '../components/brand';
import { Cart } from '../components/cart';
import { ElementContainer } from '../components/elementContainer/elementContainer';
import { DesktopBarWrapper, VerticalDivider } from './index.styled';

type DesktopProps = {
    searchString?: string;
};

export const Desktop: FC<DesktopProps> = (props) => {
    const { searchString } = props;
    const { user } = useAuth();
    const shopNavigationOpen = navigationStore.getters.useShopNavigation();
    const [displaySalesView, setDisplaySalesView] = useState(false);
    const activeGhostAccess = isGhostAccess();
    const collectionButtonRefObject = useRef<HTMLDivElement>(null);
    const collectionState = navigationStore.getters.useCollectionNavigation();
    const updateDisplayView = () => {
        toggleSalesView(user?.activeAccount?.accountNumber || '');
        const showView = isSalesViewEnabled(user?.activeAccount?.accountNumber || '');
        setDisplaySalesView(showView);

        if (showView) {
            toast.info('Einkaufspreis Änderung', 'Der Einkaufspreis wurde ausgeblendet');
        } else {
            toast.info('Einkaufspreis Änderung', 'Der Einkaufspreis wurde eingeblendet');
        }
    };

    useEffect(() => {
        const showView = isSalesViewEnabled(user?.activeAccount?.accountNumber || '');
        setDisplaySalesView(showView);
    }, [user]);
    const isDesktopLarge = useMediaQuery(BreakpointsMinQuery.xl);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const openUserDropdown = navigationStore.getters.useUserDropdownNavigation();
    useEffect(() => {
        navigationStore.setters.setCollectionButtonRef(collectionButtonRefObject);
    }, [collectionButtonRefObject]);

    const getIconName = () => {
        return activeGhostAccess ? 'Ghost' : user ? 'AccountLoggedIn' : 'Account';
    };

    const userHasOnlySalesViewRight = userHasOnlySalesView();
    const canSeeCredits = userCanSeeCredits();
    const canSeeTecselect = userCanSeeTecselect();
    const canSeePoints = canSeeCredits || canSeeTecselect;

    return (
        <DesktopBarWrapper>
            <Brand />
            {user && (
                <>
                    <VerticalDivider />
                    <ElementContainer
                        {...getTestIdProp('open-collection-button')}
                        ref={collectionButtonRefObject}
                        iconName={!collectionState ? 'Collection' : 'Close'}
                        content={isDesktopLarge ? 'Sortiment' : ''}
                        onClick={() => navigationStore.setters.setCollectionNavigation(!collectionState)}
                    />
                </>
            )}
            <Search searchString={searchString} />

            {userHasPermissions('canHidePrices') && !userHasOnlySalesViewRight && (
                <ElementContainer
                    {...getTestIdProp('hide-show-prices-button')}
                    iconName={displaySalesView ? 'NotVisible' : 'Visible'}
                    onClick={updateDisplayView}
                />
            )}
            <div className="flex" ref={wrapperRef}>
                {canSeePoints && (
                    <>
                        <VerticalDivider />
                        <PointsOverview onClick={() => navigationStore.setters.setUserDropdownNavigation(false)} />
                    </>
                )}

                <VerticalDivider />
                <ElementContainer
                    {...getTestIdProp('user-settings-dropdown-button')}
                    iconName={getIconName()}
                    content={user ? `${user?.firstName} ${user?.lastName}` : 'Anmelden / Registrieren'}
                    onClick={() => navigationStore.setters.setUserDropdownNavigation(!openUserDropdown)}
                    suffixIconName={openUserDropdown ? 'ChevronUp' : 'ChevronDown'}
                    backgroundColor={activeGhostAccess ? 'warning' : 'white'}
                    fontColor={activeGhostAccess ? 'white' : 'black'}
                />
                <VerticalDivider />
                <div className="px-medium2 pt-medium pb-medium2">
                    <Cart />
                </div>
                <VerticalDivider />
                <DropdownOverlay
                    parentRef={wrapperRef}
                    open={!!openUserDropdown}
                    onClose={() => navigationStore.setters.setUserDropdownNavigation(false)}
                >
                    <div {...getTestIdProp('user-settings-dropdown')} className="px-small3">
                        {user ? <UserSettings /> : <LoginBox />}
                    </div>
                </DropdownOverlay>
            </div>
            <ElementContainer
                iconName="Burger"
                fontColor="white"
                content="Menü"
                backgroundColor="primary"
                onClick={() => navigationStore.setters.setShopNavigation(!shopNavigationOpen)}
            />
        </DesktopBarWrapper>
    );
};
