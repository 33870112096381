import { useAuth } from '@ab-core/hooks';
import { useAccounts } from '@ab-core/hooks/user/accounts/useAccounts';
import userHasPermissions from '@ab-core/hooks/user/hasPermission';
import type { UserExceedValueCheckProps } from '@ab-core/hooks/user/permission/checks/userExceedValueCheck';
import { userExceedValueCheck } from '@ab-core/hooks/user/permission/checks/userExceedValueCheck';

export const userIsEmployee = (): boolean => {
    const { user } = useAuth();

    return user?.activeAccount?.customerClassification === 'M9';
};

export const userHasForeignVatType = (): boolean => {
    const { user } = useAuth();

    return !!user?.activeAccount?.hasForeignVatType;
};

export const userHasPickupOnly = (): boolean => userHasPermissions('hasPickUpOnly', false);

export const userCanRequestOffer = (): boolean => userHasPermissions('userCanRequestOffer', false);

export const userCanOrder = (): boolean => userHasPermissions('canOrder', false);

export const hasReturnFee = (): boolean => userHasPermissions('hasReturnFee', false);

export const needsCostCenter = (): boolean => userHasPermissions('needsCostCenter', false);

export const needsCommission = (): boolean => userHasPermissions('needsCommision', false);

export const userOnlyOrderToProject = (): boolean => userHasPermissions('onlyOrderToProject', false);

export const seeProjectPrice = (): boolean => userHasPermissions('seeProjectPrice', false);

export const userExceedValue = (price: number): boolean =>
    userHasPermissions<UserExceedValueCheckProps>('maximumOrderValue', false, {
        action: userExceedValueCheck,
        payload: { price }
    });

export const hasDiversArticle = () => userHasPermissions('diversArticle', false);

export const manualDeliveryAddressEntry = () => userHasPermissions('noManualDeliveryAddressEntry', false);

export const userHasOnlySessionCarts = () => userHasPermissions('hasSessionCarts', false);

export const userCanSetGhost = () => userHasPermissions('canSetGhost', false);

export const userCompleteDelivery = (): boolean => userHasPermissions('completeDelivery', false);

export const userCanSeeBestPrice = () => userHasPermissions('seeBestPriceContact', false);

export const userCanSeeBestPriceOnProductCard = () => userHasPermissions('seeBestPriceOnProductCard', false);

export const isGhostAccess = () => {
    const { user } = useAuth();
    const { accounts } = useAccounts(user?.activeAccount?.accountId);
    const accountId = user?.activeAccount?.accountId;

    if (!accountId || !accounts) {
        return false;
    }

    const activeAccount = accounts?.find((a) => a.accountId === accountId);

    return !!activeAccount?.isGhostAccess;
};

export const userCanSeeOutstandingReceivables = () => userHasPermissions('canSeeoutstandingReceivables', false);

export const userHasOnlySalesView = () => userHasPermissions('onlyHidePrices', false);

export const userCanSeeCredits = () => userHasPermissions('creditsSee', false);

export const userCanSeeTecselect = () => userHasPermissions('canSeeTecselect', false);

export const defaultCartIsSharedCart = () => userHasPermissions('defaultSharedCart', false);
